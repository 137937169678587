import React from 'react'
import { Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Divider, } from '@material-ui/core/'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';


const dailyStyles = makeStyles((theme) => ({
	root: {
		//
	},
	content: {
		display: 'flex', justifyContent: 'center',
	},
	text: {
		color: [theme.palette.common.black],
		padding: '1rem 2rem',
		lineHeight: '1.75em',
	},
	title: {
		fontSize: '3rem', fontWeight: 900, padding: '1rem', color: [theme.palette.navy.main],
		[theme.breakpoints.up('md')]: {
			fontSize: '4rem', fontWeight: 900, padding: '4rem 0 4rem 0', color: [theme.palette.navy.main],
		}
	},
	blockQuote: {
		background: '#5390cd', color: '#343b4d', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4rem 0 1rem 0',
		[theme.breakpoints.up('md')]: {
			margin: '6rem 0 0 0'
		}
	}
}))

export default function (props) {
	const classes = dailyStyles();

	// const SvgBG = (props) => (
	// 	<svg fill={props.fill} viewBox="0 0 100 5" preserveAspectRatio="none" width="100%" height="100%">
 //      <polygon points={getPoints(props)}  strokeWidth=".25" strokeLinecap="round"></polygon>
 //    </svg>
	// )

	// const getPoints = (props) => (
	// 	"0,0 100,0 " + props.focalPoint + ",5 0,0"
	// )


	const dailyList = () => ([
		{ link: '/', text: 'Residential' },
		{ link: '/', text: 'Commercial' },
		{ link: '/', text: 'Large loss' },
		{ link: '/', text: 'Farm/Ranch' },
		{ link: '/', text: 'Mortgage inspections' },
		{ link: '/', text: 'Underwriting Inspections'},
	])

	

	return (
		<div className={classes.root}>
	  	<Grid container spacing={0} style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
				<Grid item xs={12}>
					<Typography paragraph>Our professionally trained adjusters are experienced, graded, and rated, and understand policies, building construction, scope, and estimation.</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={0}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0 }}>	
			<Grid item xs={12} md={7}>
			<Typography variant='h5' style={{ fontWeight: 600, color: '#343b4d' }}>
				Our Daily Claims Services Include:					
			</Typography>
				<List component="ul" aria-label="list">
	    	{	dailyList().map((item, index) => (
	    		<React.Fragment key={index}>
	    			<ListItem className={classes.listItem}>
		    			<ListItemIcon>
		    				<CheckCircleIcon color="primary"/>
	  					</ListItemIcon> 
		          <ListItemText>
		          	<Typography variant='h6' style={{ color: '#323b4d' }} className={classes.listText}>
									{ item.text }  
								</Typography>
		          </ListItemText>
		        </ListItem>
		      <Divider light={true} className={classes.divider} />
		      </React.Fragment>
	    		))}
     		</List>
			</Grid>
  	</Grid>
  	<Grid container spacing={0} style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
			<Grid item xs={12}>
				<Typography variant="h5">We take pride in listening, and then creating effective solutions for our clients.</Typography>
			</Grid>
		</Grid>
	</div>
	)
}