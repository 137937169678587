import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';

const valueStyles = makeStyles(theme => ({ 
		root: {
			flexDirection: 'column',
			'& div:first-child': {
				marginLeft: 0,
				background: '#f7f7f7',
			},
			'& div:last-child': {
				marginRight: 0,
				background: '#ddd',
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			}
		},
		item: {
			padding: '1.5rem',
			fontWeight: 600,
			fontSize: '1.25rem',
			display: 'flex',
			flexGrow: 1,
			alignItems: 'center',
			justifyContent: 'center',
			textTransform: 'uppercase',
			textAlign: 'center',
			color: theme.palette.navy.main,
			background: '#eee',
		},
		icon: {
			paddingRight: '.5rem',
		}
	}));

// const StackItem = (props) => (
// 	<div>
// 		{ props }
// 	</div>
// )

export default function ValueStack(props) {
	const classes = valueStyles();

	return (
		<div className={classes.root}>
			<div className={classes.item}><Check className={classes.icon} color="primary"/> Experienced Adjusters</div>
			<div className={classes.item}><Check className={classes.icon} color="primary"/> Fast Turnaround</div>
			<div className={classes.item}><Check className={classes.icon} color="primary"/> Seamless Service</div>
		</div>
	)
}