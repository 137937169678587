import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { Grid } from '@material-ui/core';

const heroStyles = makeStyles((theme, props) => ({
  base: {
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : 'none',
  	display: 'flex',
  	flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.between('sm', 'md')]: {
      height: '28em',
      flexDirection: 'row',
    },
  	[theme.breakpoints.up('md')]: {
  		height: '32em',
      flexDirection: 'row',
    },
    [theme.breakpoints.up('xl')]: {
      height: '36em',
    },
  },
  content: {
    marginLeft: '1rem',
    marginRight: '1rem',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%',
      paddingLeft: '2.5em',
      marginRight: '2.21em',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      paddingLeft: '5em',
      marginRight: '2.21em',
    },
    '& a': {
      width: '100%',
      marginTop: '1em',
      [theme.breakpoints.up('md')]: {
        width: 'inherit',
        marginRight: '2em',
      }
    }
  },
  image: {
    width: '100%',
    minHeight: '300px',
    marginTop: '2rem',
    backgroundImage: props => props.mobilePhoto ? `url(${props.mobilePhoto})` : 'none',
    backgroundPosition: 'bottom left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: 0,
      width: '50%',
      height: '100%',
      backgroundPosition: 'center center',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      width: '50%',
      height: '100%',
      backgroundPosition: 'center center',
    }
  },
  text: {
  	color: '#343b4d',
    fontWeight: 900,
    lineHeight: '2.25rem',
    marginTop: '.5em',
  	fontSize: '2em',
  	[theme.breakpoints.up('md')]: {
  		fontSize: '2.75em',
  	}
  },
  subText: {
    color: '#343b4d',
    fontWeight: 300,
    marginTop: '1em',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25em',
      marginRight: '1rem',
    }
  },
  angle: {
    display: 'none',
    width: '12rem',
    height: '100%',
    color: '#f7f7f7',
    fill: 'current-color',
    transform: 'translate(-50%, 0)',
    margin: 0,
    zIndex: 1,
    position: 'relative',
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    }
   // hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2
  },
  action: {
  	//display: 'flex',
    position: 'relative',
    marginTop: '1rem',
    zIndex: 100,
  }
}));

export default function Hero(props) {
	const classes = heroStyles(props);

	return (
		<div className={classes.base}>
      <div className={classes.content}>
  			<div className={classes.text}>
  				{ props.text }
  			</div>
        <div className={classes.subText}>
          { props.subText }
        </div>
        <div className={classes.action}>
          { props.callToAction }
        </div>
      </div>

      <div className={classes.image}>
        <svg className={classes.angle} fill={props.backgroundColor} viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon points="50,0 100,0 50,100 0,100"></polygon>
        </svg>
      </div>

		</div>
  )
}