import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Link as RouterLink }  from 'react-router-dom';
import { IconButton } from '@material-ui/core';

const alertStyles = makeStyles((theme, props) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		background: props => props.bg ? props.bg : theme.palette.primary.main,
		// opacity: 0.5,
		width: '100%',
		padding: 0,
		boxShadow: 'inset 0 -5px 8px -5px rgba(0,0,0,0.5);', 
		[theme.breakpoints.up('md')]: {
			height: '3.5rem',
			flexDirection: 'row',
			justifyContent: 'space-between',
			// "&:hover": {
			// 	background: '#44638c',
			// 	cursor: 'pointer',
			// }
		}
	},
	icon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// background: theme.palette.navy.main,
		// background: '#325a82',
		background: '#44638c',
		width: '2.5rem',
		height: '2.25rem',
		borderRadius: '7px',
		// color: theme.palette.navy.main,
		color: '#f7f7f7',
		margin: '1rem',
	},
	alert: {
		width: '95%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center',
		}
	},
	alertText: {
		display: 'flex',
		flexDirection: 'column',
		padding: '.5rem 0 .5rem 0',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		}
	},
	title: {
		fontWeight: 900,
		color: theme.palette.navy.main,
		marginRight: '.5rem',
		// fontSize: '1.25em',
		// color: '#343b4d',
	},
	link: {
		textDecoration: 'none',
		color: '#f7f7f7',
	},
	text: {
		fontWeight: 600,
		color: '#f7f7f7',
	},
	close: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: '0 .5rem',
		[theme.breakpoints.up('md')]: {
			width: '5%',
		}
	},
	button: {
		marginRight: '1rem',
	}
}));

export default function Alert(props) {
	const classes = alertStyles(props);

	const [state, setState] = React.useState({show: props.show, expand: false});

	return (
		<React.Fragment>
		{ state.show ? 
		<div className={classes.root}>
			<div className={classes.alert}>
				<div className={classes.icon}><AnnouncementOutlinedIcon fontSize='small'/></div>
				<div className={classes.alertText}>
					<span className={classes.title} >{props.title ? props.title : null}</span>
					<RouterLink to={props.link ? props.link : '/nowhere'} className={classes.link} onClick={() => setState({...state, show: false })}>
						<span className={classes.text}>{props.text ? `${props.text}` : null }</span>
					</RouterLink>
				</div>
			</div>
			{/*<Button className={classes.button} variant="contained" size='small' style={{ background: '#343b4d', color: '#f7f7f7', fontWeight: 900 }}>Learn More</Button>*/}
			<div className={classes.close}>
				<IconButton size="small" onClick={() => setState({ ...state, show: !state.show }) }>
				<CloseRoundedIcon fontSize="small" />
				</IconButton>
			</div>
		</div>
		: null
		} 
		</React.Fragment>
	)	
}