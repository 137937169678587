import React from 'react'
import Hero from './hero/Hero'
import Services from './services/Services'
import Carriers from './carriers/CarrierOverview'
import SolutionPlan from './solutions/SolutionPlan'
import ValueStack from './value-stack/ValueStack'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const photoDetails = () => ({
	hiRes: '/images/hero-weather.jpg',
	lowRes: '/images/hero-3-m.jpg',
	alt: 'We process claims for insurance carriers and treat every policyholder with care and respect.'
})

export default function Home(props) {
	const photo = photoDetails();
	const isMobile = useMediaQuery('(max-width:700px)');

	// console.log(props.location);

	const heroProps = (props) => ({
		text: 'We process claims for insurance carriers and treat every policyholder with care and respect.',
		// subText: 'I need some text for this section. A couple of well formed, informational sentances to convey what this portion is about!',
		mobilePhoto: photo.lowRes,
		backgroundColor: '#e5e7eb',
	})
	
	return (<>
		<Hero callToAction={props.callToAction} {...heroProps(props) } />

		<ValueStack />

		<Services />

		<Carriers callToAction={props.callToAction} />

		<SolutionPlan callToAction={props.callToAction} isMobile={ isMobile }/>
	</>)
}