import React from 'react'
import { Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Divider, } from '@material-ui/core/'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';


const staffAugmentationStyles = makeStyles((theme) => ({
	root: {
		// background: '#e5e7eb',
		// background: '#f0f0f0',
		// color: '#e5e7eb'
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: { 
		fontSize: '3rem', fontWeight: 900, padding: '3rem 1rem', color: '#343b4d',
		[theme.breakpoints.up('md')]: {
			fontSize: '4rem', fontWeight: 900, padding: '4rem 0 2rem 0', color: '#343b4d',
		}
	},
	text: {
		// padding: '1rem 2rem',
		// lineHeight: '1.75em',
	},
	img: {
		display: 'block',
	},
	bottomAngle: {
		position: 'relative',
		marginTop: "-5.25rem",
	},
	topAngle: {
		position: 'relative',
		top: 0,
		zIndex: 10000,
	},
	leftAngle: {
		position: 'relative',
		top: '-2.5rem'
	},
	blockQuote: {
		background: '#5390cd', color: '#343b4d', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4rem 0 1rem 0',
		[theme.breakpoints.up('md')]: {
			margin: '6rem 0 0 0'
		}
	}
}));

export default function (props) {
	const classes = staffAugmentationStyles();

	// const SvgBG = (props) => (
	// 	<svg fill={props.fill} viewBox="0 0 100 5" preserveAspectRatio="none" width="100%" height="100%">
 //      <polygon points={getPoints(props)}  strokeWidth=".25" strokeLinecap="round"></polygon>
 //    </svg>
	// )

	// const getPoints = (props) => (
	// 	"0,0 100,0 " + props.focalPoint + ",5 0,0"
	// )


	const staffAugmentationList = () => ([
		{ link: '/', text: 'Staffing in your office or remotely' },
		{ link: '/', text: 'File reviews' },
		{ link: '/', text: 'Audits' },
		{ link: '/', text: 'Litigation claims' },
		{ link: '/', text: ' Claim overflow' },
		{ link: '/', text: 'Appraisal/ arbitration' },
		{ link: '/', text: 'Issuance of claim payments' },
		{ link: '/', text: 'Fast track claims' },
		{ link: '/', text: 'Claim personnel' },
	])

	

	return (
		<div className={classes.root}>
		  	<Grid container spacing={0} style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
			<Grid item xs={12}>
				<Typography paragraph>Our team of examiners/adjusters have clear understanding of coverage issues including policy provisions related to exclusions and endorsements. They can review new losses, examine coverage, and make payments. And, they have extensive experience in filling in during your time of need on long- and short-term assignments.</Typography>
			</Grid>
		</Grid>
		<Grid container spacing={0}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0 }}>	
			<Grid item xs={12} md={7}>
			<Typography variant='h5' style={{ fontWeight: 600, color: '#343b4d' }}>
				Our Staff Augmentation Services Include:					
			</Typography>
				<List component="ul" aria-label="list">
	    	{	staffAugmentationList().map((item, index) => (
	    		<React.Fragment key={index}>
	    			<ListItem className={classes.listItem}>
		    			<ListItemIcon>
		    				<CheckCircleIcon color="primary"/>
	  					</ListItemIcon> 
		          <ListItemText>
		          	<Typography variant='h6' style={{ color: '#323b4d' }} className={classes.listText}>
									{ item.text }  
								</Typography>
		          </ListItemText>
		        </ListItem>
		      <Divider light={true} className={classes.divider} />
		      </React.Fragment>
	    		))}
     		</List>
			</Grid>
  	</Grid>
  	<Grid container spacing={0} style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
			<Grid item xs={12}>
				<Typography variant="h5">Day in and day out, we attentively maintain our commitment to excellent customer service.</Typography>
			</Grid>
		</Grid>
	</div>
	)
}