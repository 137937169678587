import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box  } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

const testamonialStyles = makeStyles((theme, props) => ({
	root: {
		// display: '',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 16,
		background: '#e5e7eb',
		display: props => props.show ? 'flex' : 'none',
		animation: `$slideFadeIn .5s ${theme.transitions.easing.easeOut}`,
	},
	photo: {
		width: '9em',
		height: 'auto',
		display: 'block',
		borderRadius: '1000px',
		border: `.5em solid ${theme.palette.primary.main}`,
		margin: '0',
		[theme.breakpoints.up('md')]: {
			width: '12em',
			margin: '4em',
		}
	},
	photoContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	name: {
		fontWeight: 900,
		fontSize: '1em',
		color: [theme.palette.primary.main],
		paddingTop: '1em',
		paddingBottom: '4em',
		[theme.breakpoints.up('md')]: {
			paddingBottom: '0',
		}
	},
	text: {
		fontSize: '1em',
		fontWeight: 300,
	},
	stars: {
		color: [theme.palette.secondary.main],
	},
  '@keyframes slideFadeIn' : {
  	'35%, 100%': {
    	opacity: 0,
    	transform: 'translate(35px, 0)',
  	},
  	'0%': {
    	opacity: 0,
  	},
  	'100%': {
    	opacity: 1,
    	transform: 'translate(0, 0)',
  	}
  },
}))

export default function Testamonial(props) {
	const classes = testamonialStyles(props);

	return (
		<div className={classes.root} data-has-animation>
			<Grid container spacing={4} className={classes.root}>
				<Grid item sm={12} md={3} >
					<div className={classes.photoContainer}>
						<img src={props.photoLink} className={classes.photo} alt={props.title}/>
					</div>
				</Grid>
				<Grid item sm={12} md={7}>
					<Box component="fieldset" mb={3} borderColor="transparent" style={{ margin: 0, padding: 0 }}>
	        	<Typography component="legend" style={{ margin: 0, padding: 0, fontWeight: 900, fontSize: '1.10em', color: '#323b4d' }}>{props.title}</Typography>
	      		<Rating name="read-only" defaultValue={props.rating} readOnly className={classes.stars} />
	  			</Box>
					<Typography component="p" className={classes.text}>
						{props.body}
					</Typography>
					<Typography element="p" className={classes.name}>- {props.name}, {props.company} </Typography>
				</Grid>
			</Grid>
		</div>
	)
}