import React, { useEffect } from 'react'
import Testamonial from './Testamonial'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const testamonialStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		background: '#e5e7eb',
	}	,
	control: {
		display: 'none', 
		[theme.breakpoints.up('md')]: {
			display: 'flex', 
			fontSize: 40,
			paddingLeft: 16,
			paddingRight: 16,
		}
	}
}));

function Testamonials(props) {
	const classes = testamonialStyles();
	const isMobile = useMediaQuery('(max-width:700px)');
	const [currentTestamonial, setCurrentTestamonial] = React.useState(0)
	const [focus, setFocus] = React.useState(false)
	const delay = 3000;

	useEffect(() => {
		if (!focus && !isMobile) {
			const timer = setInterval(() => {
				next();
			}, delay);
			return () => clearInterval(timer);
		}
	});

	const next = () => {
		let value = currentTestamonial;
		value < props.testamonials.length -1 ? value++ : value = 0;
		setCurrentTestamonial(value);
	}

	const previous = () => {
		let value = currentTestamonial;
		value > 0 ? value-- : value = props.testamonials.length -1;
		setCurrentTestamonial(value);
	}

	const showTestamonial = (idx) => {
		return idx === currentTestamonial ? true : false
	}

	const toggleFocus = (e) => {
		if (e.type === 'mouseenter' || e.type === 'touchstart')
			setFocus(true);

		if (e.type === 'mouseleave' || e.type === 'touchend') 
			setFocus(false);
	}

	const Control = (props) => (
		<props.component className={classes.control} onClick={props.handleClick} />
	)

	return (
		<div className={classes.root} onMouseEnter={toggleFocus} onMouseLeave={toggleFocus} onTouchEnd={next}>
			<Control component={ArrowBackIosIcon} direction='previous' handleClick={previous} />
			{props.testamonials.map((testamonial, idx) => (
				<Testamonial {...testamonial } show={showTestamonial(idx)} key={idx} />
			))}
			<Control component={ArrowForwardIosIcon} direction='next' handleClick={next} />
		</div>
	)
}

export default Testamonials