import React from 'react'
// import Hero from './hero/Hero'
import { Grid, Menu, MenuItem, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CatastropheClaims from './services/CatastropheClaims';
import ThirdPartyAdministration from './services/ThirdPartyAdministration';
import StaffAugmentation from './services/StaffAugmentation';
import DailyClaims from './services/DailyClaims';
import CallCenter from './services/CallCenter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandablePanel from './panel/ExpandablePanel';
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded';
import WavesRoundedIcon from '@material-ui/icons/WavesRounded';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';
import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { HashLink as Link } from 'react-router-hash-link';
// import AdjusterMap from './services/AdjusterMap';

const servicesStyles = makeStyles((theme) => ({
	root: {
		background: 'linear-gradient(#efefef, #f9f9f9)',
	},
	flex: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: {
		color: '#5390cd', fontSize: '3rem', fontWeight: 900, lineHeight: '3rem',
		background: 'linear-gradient(to right, #5390cd, #43D6A7)',
  	'-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    margin: '2rem 2rem'
	},
	headingText: {
		color: '#f7f7f7', fontWeight: 100, width: "90%",
		[theme.breakpoints.up('md')]: {
			width: "50%",
			textAlign: 'center'
		}
	},
	serviceMenu: {
		marginBottom: '4rem',
		fontWeight: 900,
		[theme.breakpoints.up('md')]: {
			marginBottom: 0,
		}
	},
	menuLink: {
		color: 'inherit',
		textDecoration: 'none',
		width: '100%',
	}
}));

export default function Services (props) {
	const classes = servicesStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
  	setAnchorEl(null);
  }

	const getPoints = (props) => (
		"0,0 100,0 " + props.focalPoint + ",5 0,0"
	)

	const SvgBG = (props) => (
		<svg fill={props.fill} viewBox="0 0 100 5" preserveAspectRatio="none" width="100%" height="100%">
      <polygon points={getPoints(props)}  strokeWidth=".25" strokeLinecap="round"></polygon>
      {/*<line x1="40" x2="120" y1="100" y2="100" stroke="red" stroke-width="20" stroke-linecap="round"/>*/}
    </svg>
	)

	const copy = () => ([
		`When you’re looking for someone to manage your property insurance TPA program, your priorities are expertise, resources, and facilities. That’s Claim Consultant Group. 
		Our network of dedicated adjusters and vendor partners are committed to extraordinary customer service to your insureds. We understand the challenges that diverse state statutory requirements present and have the experience to ensure compliance.`,
		`No matter how well you plan your staffing, there are times when claim volumes will exceed your internal capacity to process them. Claim Consultant Group specializes in staff augmentation services.
		Most commonly, we provide adjusters to assist with claim handling during catastrophic events. However we also offer branch support during situations ranging from staff reductions and illnesses to maternity leave and vacations.`,
		`A catastrophic event can disrupt your policyholder’s life. Don’t let it disrupt the claims standard they have come to expect from you as their trusted carrier.
		When catastrophic events cause damage to properties, the insurance claims process can increase the sense of uncertainty and stress your policyholders feel. Claim Consultant Group not only provides excellent service to you, the carrier, but to your insureds as well.`,
		`What do you need most? We work on Third-Party Administration (TPA) programs, Fast Track programs, Appraisals, Partial Investigations, Underwriting Inspections, and Full Claim Assignments.
		Claim Consultant Group provides customized, nationwide claim management services to meet our business partners’ specialized needs.`,
		`There are times when you need to step away from the phones. We’ve got you covered. Just route your calls to Claim Consultant Group. We’ll take your calls and provide you with a report for follow up.`
	])

	const services = () => ([
		{id: 'tpa', title: 'Third Party Administration (TPA)', icon: <SupervisorAccountIcon/>, hiddenComponent: <ThirdPartyAdministration/>, 'images': [{ alt: 'TPA', src: '/images/tpa.jpg', mobileSrc: '/images/tpa-m.jpg'}], copy: copy()[0] },
		{id: 'staff-augmentation', title: 'Staff Augmentation', icon: <GroupAddRoundedIcon/>, hiddenComponent: <StaffAugmentation/>, 'images': [{alt: 'Staff Augmentation', src: '/images/staff-augmentation.jpg', mobileSrc: '/images/staff-augmentation-m.jpg'}], copy: copy()[1] },
		{id: 'daily', title: 'Daily Claims', icon: <WbSunnyRoundedIcon/>, hiddenComponent: <DailyClaims/>, 'images': [{alt: 'daily', src: '/images/daily-2.jpg', mobileSrc: '/images/daily-2-m.jpg' }], copy: copy()[3] },
		{id: 'catastrophe', title: 'Catastrophe Claims', icon: <WavesRoundedIcon/>, hiddenComponent: <CatastropheClaims/>, 'images': [{alt: 'Catastrophe Claims', src: '/images/damage-2.jpg', mobileSrc: '/images/damage-2-m.jpg'}], copy: copy()[2] },
		{id: 'call-center', title: 'Call Center', icon: <HeadsetMicRoundedIcon/>, hiddenComponent: <CallCenter/>, 'images': [{alt: 'Call Center', src: '/images/call-center.jpg', mobileSrc: '/images/call-center-m.jpg'}], copy: copy()[4] }
	])

	const panelProps = (props, index) => ({
		key: index,
		idx: index,
		id: props.id,
		title: props.title,
		images: props.images,
		photo: props.images[0].src,
		mobilePhoto: props.images[0].mobileSrc,
		icon: props.icon,
		copy: props.copy,
		btnText: 'Read More',
		hiddenComponent: props.hiddenComponent,
		backgroundPosition: props.backgroundPosition
	})

	return (
		<div className={classes.root}>

			<div className={classes.flex} style={{ minHeight: '82vh', flexDirection: 'column', background: props.bg ? `url(${props.bg})` : '#1d212b', }}>
				<h3 className={classes.title}>Our Expertise, Your Peace Of Mind.</h3>
				<h4 className={classes.headingText}>Sometimes, your staff simply cannot meet claim volume demand, which makes you feel stressed. We believe meeting increased demand shouldn’t be so hard.</h4>

      		<Button onClick={handleClick} className={classes.serviceMenu} color="secondary" variant="contained" size="large" aria-controls="service-menu" aria-haspopup="true" endIcon={<ExpandMoreIcon/>} >
        		&nbsp;&nbsp;&nbsp;Services&nbsp;&nbsp;&nbsp;
     		 	</Button>

      		<Menu
      			id="service-menu"
		        anchorEl={anchorEl}
		      	keepMounted
		        open={Boolean(anchorEl)}
		        anchorOrigin={{vertical: 'top', horizontal: -30}}
		        onClose={handleClose}
		      >
		     	{ 
			     	services().map((service, idx) => 
			     		<MenuItem 
			     			key={service.id} 
			     			onClick={handleClose} 
			     		>
			     			<Link 
			     				key={service.idx}
			     				to={`#${service.id}`} 
			     				data-section={service.id} 
			     				className={classes.menuLink}
			     				smooth
		     				>
		     					{service.title}
		     				</Link>
			     		</MenuItem>
			     	)	
			   	}
    		</Menu>

				{/*<AdjusterMap />*/}

			</div>

			<Grid container spacing={0}>
				<Grid item xs={12} style={{ marginTop: '-.01em' }}>
					<SvgBG fill="#1d212b" focalPoint={50} />
				</Grid>
			</Grid>

			{
				services().map((service, index) => {
					return (
						<ExpandablePanel {...panelProps(service, index)} />
					)
			})
		}

			
  </div>
	)
}