import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography  } from '@material-ui/core';
// import { Link as RouterLink }  from 'react-router-dom';
// import Testamonial from './testamonial/Testamonial'
import Hero from './hero/Hero'

const carrierStyles = makeStyles((theme) => ({
	root: {
		background: '#ededed',
		padding: 24,
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		// alignItems: 'center',
	},
	article: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		"& p:first-child": {
			marginTop: '4rem',
		},
		"& p": {
			fontSize: '1.25em',
			padding: '.5rem 1.5rem',
			[theme.breakpoints.up('md')]: {
				padding: '.5rem 24rem',
			},
			[theme.breakpoints.up('xl')]: {
				padding: '.5rem 28rem',
			},		
		}
	},
	text: {
		fontSize: '1.25em',
		margin: 24
	},
	emphasisText: {
		fontSize: '1.5em',
		color: [theme.palette.primary.main],
		fontWeight: 600,
		marginTop: 24,
		marginBottom: 24,
		textAlign: 'center'
	},
	button: {
		marginTop: 24,
		marginBottom: 24,
	},
	buttonText: {
		fontWeight: 900, color: '#323b4d',
	}
}))

const photoDetails = () => ({
	hiRes: '/images/about-2.jpg',
	lowRes: '/images/about-2-m.jpg',
	alt: 'Daily Claims'
})

export default function About (props) {
	const classes = carrierStyles();
	const photo = photoDetails();

	const heroProps = () => ({
		// text: 'We treat every policyholder with care and respect.',
		text: 'Claim Consultant Group Brings 75 Years of Experience to Independent Adjuster Services',
		//subText: 'With decades of cumulative experience we\'ve seen it all. We will handle your customers\' claims with efficiency, care and precision. Every. Single. Time.',
		// photo: photo.hiRes,
		mobilePhoto: photo.lowRes,
		backgroundColor: '#e5e7eb'
	})

	// const testamonialProps = () => ({
	// 	name: 'Josh Salge',
	// 	company: 'Farmer’s Mutual Fire Insurance Association',
	// 	photoLink: '/images/josh-salge.jpg',
	// 	title: 'From adjuster assignments to billing, they’ve got the process down.',
	// 	rating: 5,
	// })


	return (
		<React.Fragment>
			<div style={{ background: '#ededed'}}>
				<Grid container spacing={0} className={classes.flex}>
					<Grid item xs={12}>
						<Hero callToAction={props.callToAction} {...heroProps() } />
					</Grid>
				</Grid>
		  </div>

	  <div className={classes.root}>
	  	<Grid container spacing={0} className={classes.flex}>
				<Grid item xs={12} md={8} xl={8} className={classes.flex}>
					<Typography variant='h2' style={{ fontWeight: 900, color: '#343b4d', textAlign: 'center', marginTop: '2rem' }}>Why Claim Consultant Group?</Typography>
				</Grid>
			</Grid>
	  </div>

	 <div className={classes.root} style={{ padding: '0 0 2rem 0' }}>
		<Grid container spacing={0} className={classes.flex} >
			<Grid item xs={12} md={4} xl={4}>
				<Typography paragraph className={classes.text}>We know you’re one of the best carriers in the business, and you expect excellence from your independent adjusters. At Claim Consultant Group, we get it. Since 2010, we’ve processed thousands of claims and helped our partner carriers deal with the stress of high claim volumes. We’re here to give you peace of mind.</Typography>
			</Grid>
			<Grid item xs={12} md={4} xl={4}>
				<Typography paragraph className={classes.text}>The entire team is committed to helping you manage claims so you can stop losing sleep. The process is simple. Once you schedule a call and we create a customized Claim Solution Plan, you’ll be on the road to that peace of mind we mentioned.</Typography>
			</Grid>
			<Grid item xs={12} className={classes.flex}>
					{ props.callToAction }
				</Grid>
			</Grid>
			<Grid item xs={12} className={classes.article}>
				<Typography paragraph>Aaron Crain, President and CEO of Claim Consultant Group, founded the company 2010. He’s a third-generation claims adjuster and he has assembled an exceptional team with experience that spans every aspect of the insurance field. “Our business is different,” says Aaron, “because of our management style, our emphasis on technology, and the commitment to support our adjusters.</Typography>
				<Typography paragraph>Describing himself as very technology driven, Aaron emphasized that there’s no “end game” when it comes to technology. “We always have to be innovative,” he says. “We’re able to do many things other companies can’t do, because of our focus on data management and integration. For example, we can monitor our performance in real time. We know how long a claim is open and how quickly claims are being paid. And we’re transparent, providing our clients with detailed reports.”</Typography>
				<Typography paragraph>The Claim Consultant Group team establishes clear processes and structure for every carrier they serve. But they also recognize that no two carriers are alike. “We listen,” says Aaron. “So many of our clients come to us when they start to feel overwhelmed, or when they don’t feel like they’re able to meet the needs of their policyholders. We take the time to understand the problem, and then we find solutions.”</Typography>
				<Typography paragraph>The executive team alone brings 75 years of excellence to the table; and on any given day, they, along with their experienced adjusters and support staff, are serving clients in at least 25 states.</Typography>
				<Typography paragraph>Call today and find out how Claim Consultant Group can help your business thrive.</Typography>
			</Grid>
			<Grid item xs={12} className={classes.flex} style={{ margin: '2rem 0' }}>
				<Typography variant="h3">Ready to get started?</Typography>
			</Grid>
			<Grid item xs={12} className={classes.flex}>
				{props.callToAction}
			</Grid>
		</div>

	{/*	<Testamonial {...testamonialProps() } > 
			“When I think about what sets Claim Consultant Group apart from other IA firms, it really comes down to attitude. They’re here to help and serve. They’re responsive and transparent. And from adjuster assignments to billing, they’ve got the process down. They make my life easier, and they’re ready to go the minute you need them.”
		</Testamonial>*/}

	</React.Fragment>
	)
}