import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Form from '../call-to-action/Form'; 
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import LocationIcon from '@material-ui/icons/LocationOn';
// import FaxIcon from '@material-ui/icons/Print';

const contactStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		}
	},
	left: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			alignItems: 'center',
			width: '35%',
			padding: '0 1rem',
			marginBottom: 0,
		}
	},
	address: {
		color: [theme.palette.primary.main]
	},
	map: {
		width: '100%', height: '225px',
		marginTop: '2rem',
		marginBottom: '2rem',
		[theme.breakpoints.up('md')]: {
			width: '80%',
			marginBottom: '0',
		}
	},
	right: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '65%',
			padding: '0 1rem',
		}
	},
	small: {
		// fontWeight: 900,
		fontSize: '1rem',
	},
	phone: {
		color: [theme.palette.primary.main],
		fontSize: '1.25em',
		fontWeight: 700,
		marginBottom: '1rem',
	},
	email: {
		color: [theme.palette.primary.main],
		fontWeight: 700,
		margin: 0,
		marginBottom: '1rem',
	},
	icon: {
		fontSize: '4rem',
		marginBottom: '1rem',
		color: [theme.palette.navy.main]
	},
	contactInfo: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			marginBottom: '2rem',
			alignItems: 'flex-start',
		}
	},
	contactItem: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		padding: '1rem 0',
		[theme.breakpoints.up('md')]: {
			alignItems: 'center',
			padding: '0 2rem',
		}
	},
	submit: {
		marginTop: '1rem',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 'auto',
		}
	}
}))

const contactProps = () => ({
	address: {
		street: '308 S. Meadowlark',
		city: 'Lakeway',
		state: 'TX',
		zip: '78734'
	},
	addressLink: `https://www.google.com/maps/place/Claim+Consultant+Group/@30.3576096,-97.9546856,17z/data=!3m1!4b1!4m5!3m4!1s0x8644ca3314ca5fed:0x37a04afb15fbc9d6!8m2!3d30.357605!4d-97.9524969`,
	phone: `(888) 645-1480`,
	fax: `512-650-1033`,
	emails: {
		info: 'info@claimconsultantgroup.com',
		dispatch: 'dispatch@claimconsultantgroup.com',
		support: 'support@claimconsultantgroup.com',
		iASupport: 'IAsupport@claimconsultantgroup.com'
	}
})

function ContactPage () {
	const classes = contactStyles()
	const contact = contactProps()

	// const [showMessageBox, setShowMessageBox] = React.useState(false);

	const [formData, setFormData] = React.useState({
 		message: `What's on your mind...`,
 		email: '',
 		name: '',
 		company: '',
 	});

 	const required = () => ([formData.name, formData.email, formData.company]);

	const valid = () => {
 		return required().every(value => value !== '')
 	}

	const handleFormChange = (event) => {
 		const target = event.target
 		setFormData({...formData, [target.id]: target.value});
 	}

 	const request = () => ({
	headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": 'application/json'
  },
  method: 'POST',
  body: JSON.stringify(formData),
  mode: 'cors'
})

 const submitFormData = () => {
 	if (valid()) {
 		return window.fetch('https://hr.ccgops.net/api/call-to-action/email', request())
		.then(response => response.text())
		.then(data => console.log(data))
		.catch(error => console.log(error))
 	}
 		console.error('All fields are required, looks like you forgot something.')
 }


	return (
		<div className={classes.root}>
			<div className={classes.left}>
				<LocationIcon className={classes.icon}/>
				<small className={classes.small}>Corporate Office</small>
				<h3 className={classes.address}>{ contact.address.street } <br /> { contact.address.city } { contact.address.state }, { contact.address.zip }</h3>
				<a href={ contact.addressLink } target="_blank" rel="noopener noreferrer">Directions</a>
				<iframe className={classes.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3442.7658080542215!2d-97.95468558487745!3d30.357604981769395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644ca3314ca5fed%3A0x37a04afb15fbc9d6!2sClaim%20Consultant%20Group!5e0!3m2!1sen!2sus!4v1593540338505!5m2!1sen!2sus"  title="CCG Location" frameBorder="0" style={{border:0}} aria-hidden="false" tabIndex="0"></iframe>
			</div>
			<div className={classes.right}>
				<div className={classes.contactInfo}>
					<div className={classes.contactItem}>
						<PhoneIcon className={classes.icon}/>
						<small className={classes.small}>Give Us a Call Anytime at:</small>
						<a href={`tel:${contact.phone}`} className={classes.phone}><span>{ contact.phone }</span></a>
						<small className={classes.small}>You may fax us at:</small>
						<a href={`tel:${contact.fax}`} className={classes.phone}><span>{ contact.fax }</span></a>
					</div>

					<div className={classes.contactItem}>
						<EmailIcon className={classes.icon}/>
						<small className={classes.small}>General Inquiries</small>
						<a href={`mailto:${contact.emails.info}`} className={classes.email} ><span>{ contact.emails.info }</span></a>
						<small className={classes.small}>Email Claim Assignments</small>
						<a href={`mailto:${contact.emails.dispatch}`} className={classes.email} ><span>{ contact.emails.dispatch }</span></a>
						<small className={classes.small}>Adjuster IT Assistance</small>
						<a href={`mailto:${contact.emails.iASupport}`} className={classes.email}><span>{ contact.emails.iASupport }</span></a>
					</div>
				</div>
				<div>
					<h3 style={{ color: '#343b4d' }}>Send Us A Message</h3><hr style={{ backgroundColor: '#ccc', height: '1px', border: 0 }}/>
					<Form data={formData} handleFormChange={handleFormChange} showMessageBox />
					<Button className={classes.submit} color="primary" variant="contained" onClick={submitFormData}>Send</Button>
				</div>
			</div>
		</div>
	)
}

export default ContactPage