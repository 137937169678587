import React from 'react'
import { Grid } from '@material-ui/core';
import { PersonOutline as PersonOutlineIcon, Business as BusinessIcon, MailOutlined as MailOutlinedIcon }  from '@material-ui/icons/';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { makeStyles } from '@material-ui/core/styles';

const callToActionFormStyles = makeStyles((theme) => ({
	input: {
		padding: '.5rem 0'
	},
	icon: {
		paddingRight: '.5rem',
		color: theme.palette.navy !== undefined ? theme.palette.navy.main  : '#000'
	}
}))

export default function Form(props) {
	const classes = callToActionFormStyles()

	return (
		<React.Fragment>
 		<Grid container spacing={0}>
  		<Grid item xs={12} md={6} role="form">
        <TextField
        	className={classes.input}
          margin="dense" id="name"
          label="Full Name" placeholder="Firstname Lastname"
          value={props.data.name}
          onChange={props.handleFormChange}
          type="text"
          InputProps={{
	          startAdornment: (
	            <InputAdornment position="start">
	              <PersonOutlineIcon className={classes.icon}/>
	            </InputAdornment>
	          ),
	        }}
	        fullWidth 
        />
    	</Grid>
    	<Grid item xs={12} md={6}>
        <TextField
        	className={classes.input}
          margin="dense"
          id="company"
          label="Company"
          type="text"
          placeholder="Company – Your Title"
          value={props.data.company}
          onChange={props.handleFormChange}
          fullWidth
          InputProps={{
	          startAdornment: (
	            <InputAdornment position="start">
	              <BusinessIcon className={classes.icon}/>
	            </InputAdornment>
	          ),
	        }}
        />
      </Grid>
    	</Grid>
      <TextField
      	className={classes.input}
        margin="dense"
        id="email"
        label="Email Address"
        type="email"
        placeholder="yourmail@example.com"
        fullWidth
        onChange={props.handleFormChange}
        value={props.data.email}
         InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlinedIcon className={classes.icon}/>
            </InputAdornment>
          ),
        }}
      />
      { props.showMessageBox 
      		?
		      <Grid item xs={12}>
		      	<TextareaAutosize rowsMin={6} id="message" value={props.data.message} onChange={props.handleFormChange} style={{width: '100%'}} />
		      </Grid>
		      : 
		      null
      	}
	</React.Fragment>)
}