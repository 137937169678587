import React from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, Link as RouterLink }  from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CCGTheme from './components/style/Theme';
import Top from './components/navigation/Top';
import Navigation from './components/navigation/Navigation';
import Footer from './components/footer/Footer';
import {Button} from '@material-ui/core';
import './App.css';
import Alert from './components/alerts/Alert';
import Home from './components/Home';
import Adjusters from './components/Adjusters';
import Services from './components/Services';
import About from './components/About';
import CallToAction from './components/call-to-action/CallToAction'
import CallToActionDialog from './components/call-to-action/Dialog'
import Page from './components/page/Page'
import Covid from './components/page/covid'
import ContactPage from './components/page/contact'
import InView from './utilities/InView'
import Testamonials from './components/testamonial/Testamonials'

export default function App() {

  const [showCta, setCta] = React.useState(false);

  function toggleShowCta() {
    return setCta(!showCta);
  }

  const isMobile = useMediaQuery(CCGTheme.breakpoints.between('xs', 'sm'));

  console.log(CCGTheme.breakpoints.between('xs', 'sm'));

  const testamonials = () => ([
  { name: 'Leon Capers', company: 'Independent Claims Adjuster', photoLink: '/images/leon-capers.jpg', title: 'Management expresses genuine concern and appreciation', rating: 5, body: `I have been an independent claims adjuster for 10 years. At CCG, I found my experience to be different than my prior assignments. The principals and management express genuine concern and appreciation for those working WITH them. Based on my experience at CCG, they will always get first dibs.` },
  { name: 'Ryan Vickery', company: 'CCG Adjuster', photoLink: '/images/vickery.jpg', title: 'Everybody’s treated with respect and loyalty', rating: 5, body: `As an adjuster, it can feel like there are a million obstacles in your career path. For example, with some independent claims businesses, you get treated like a number. And, it can take several weeks – or even months – to get paid. But with CCG, because they know what it’s like to work in the field, everybody’s treated with respect and loyalty. We get to work with great clients nationwide and we feel like we’re part of a team. That’s a breath of fresh air in the insurance industry.` },
  { name: 'Josh Salge', company: 'Farmer’s Mutual Fire Insurance Association', photoLink: '/images/josh-salge.jpg', title: 'From adjuster assignments to billing, they’ve got the process down.', rating: 5, body: `When I think about what sets Claim Consultant Group apart from other IA firms, it really comes down to attitude. They’re here to help and serve. They’re responsive and transparent. And from adjuster assignments to billing, they’ve got the process down. They make my life easier, and they’re ready to go the minute you need them.` }
])

  const routes = [
  { label: 'Home', path: '/', exact: true, inNav: true, component: (props) => <Home {...props} callToAction={<CallToAction clicked={toggleShowCta} />} />},
  { label: 'Services', path: '/services', exact: false, inNav: true, component: (props) => <Services {...props} /> },
  { label: 'Adjusters', path: '/adjusters', exact: false, inNav: true, component: (props) => <Adjusters {...props} /> },
  { label: 'About', path: '/about', exact: false, inNav: true, component: (props) => <About {...props} callToAction={<CallToAction clicked={toggleShowCta} />} /> },
  { label: 'Contact', path: '/contact', exact: false, inNav: true, component: (props) => <Page {...props} {...contactProps() } /> },
  { label: 'COVID-19', path: '/covid-19', inNav: false, exact: false, component: (props) => <Page {...props} {...covidPageProps() } /> }
]

const dialogProps = () => ({
  title: "Let's Talk About Your Needs",
  description: `Give us a call now or anytime, you may also contact us by clicking the "Email US' button below.`,
  btnText: '(888) 645-1480'
});

const covidPageProps = () => ({
  title: `Our Valued Roster of Adjusters,`,
  content: <Covid />,
})

const contactProps = () => ({
  title: ``,
  headerImage: '/images/contact.jpg',
  content: <ContactPage />,
  callToAction: () => <CallToAction clicked={toggleShowCta} />
})

const alertProps = () => ({
  title: 'COVID-19 Info',
  text: 'Read our COVID-19 guide',
  link: '/covid-19',
  show: true,
  bg:'linear-gradient(to right, #5390cd, #43D6A7)',
})

// waits for an element to be in the viewport 
// before its animation will start...
InView()

  return (
  <ThemeProvider theme={CCGTheme}>
    <Router>

      <Top />

      <CallToActionDialog show={showCta} clicked={toggleShowCta} {...dialogProps() } />

      <Navigation 
        routes={routes} 
        isMobile={isMobile}
        callToAction={<CallToAction clicked={toggleShowCta} size="small" disableElevation={true} styling={{ marginRight: '.25rem' }}/>} 
      />

       <Alert { ...alertProps() } />

        <Switch>
         {routes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact} children={route.component} />
          ))}
         <Route path="*" key={ routes.length - 1 } component={NoMatch} />
        </Switch>

      <Testamonials testamonials={ testamonials() } />

      <Footer theme={CCGTheme} routes={routes.filter(route => route.inNav === true)} />

    </Router>
  </ThemeProvider>
  )
}

const NoMatch = () => {
  let location = useLocation();

  return (
    <div style={{ minHeight: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        <h1 style={{ fontSize: '2em', color: '#343b4d', margin: 0, padding: '1em', textAlign: 'center' }}><span style={{ color: [CCGTheme.palette.primary.main] }}>404</span> <span style={{ color: '#ccc' }}>|</span> Not Found</h1>
        <p style={{ textAlign: 'center' }}>Sorry, we Couldn't find anything at: <code>{location.pathname}</code></p>
        <div style={{display: 'flex', justifyContent: 'center', padding: '2em'}}>
          <Button component={RouterLink} to='/' variant="contained" color='primary' style={{fontWeight: 900}}>
           Go Home
          </Button>
        </div>
      </div>
    </div>
  )
}