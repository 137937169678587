 function InView () {
  // console.log(elements)

  let scroll = window.requestAnimationFrame;
  let pausedElements = document.querySelectorAll("[data-has-animation='true']");

  function inViewport(el) {
    let rect = el.getBoundingClientRect();
    return (
      (rect.top <= 0
        && rect.bottom >= 0)
      ||
      (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight))
      ||
      (rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    );
  }

  function loop() {
    if (pausedElements.length < 1) pausedElements = document.querySelectorAll("[data-has-animation='true']");

    pausedElements.forEach((element, idx) => {
      // console.log(element.classList.contains('pause'))
      return inViewport(element)
        ? element.classList.remove('pause')
        : element.classList.add('pause')
    });

    scroll(loop)
  }

  loop()
 }
 export default InView