import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import Hero from '../hero/Hero'

const carrierOverviewStyles = makeStyles(theme => ({
	root: {
		padding: 24,
		// position: 'relative',
		[theme.breakpoints.up('xl')]: {
  		// minHeight: '400px'
  		marginRight: '12em',
  		marginLeft: '12em',
  	}
	},
	solutions: {
		flexGrow: 1,
		justifyContent: 'center',
  },
  heading: {
    padding: '2em 0 1em 0',
    color: theme.palette.common.white,
    fontWeight: 300,
    fontSize: '14pt'
  },
  imageWrapper: {
  	width: '100%',
  	display: 'flex',
  	justifyContent: 'center',

  },
  weGetItImage: {
  	maxWidth: '100%',
  	height: 'auto',
  	margin: '0 auto',
  	padding: 0,
  	display: 'block',
  },
  weGetIt: {
    // padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    color: theme.palette.common.white,
    fontWeight: 900,
  },
  listHeading: {
  	// width: '100%',
  	color: theme.palette.primary.main,
  	fontWeight: 300,
  	// paddingBottom: theme.spacing(3),
  	textShadow: `2px 2px 1px ${theme.palette.common.black}`
  },
  list: {
  	paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0,
  	[theme.breakpoints.up('md')]: {
  		paddingTop: 0, paddingBottom: 0, paddingLeft: '3em', paddingRight: '3em'
  	},
  },
  listItem: {
  	paddingLeft: 0, paddingRight: 0
  },
  listText: {
    paddingTop: theme.spacing(.75),
    paddingBottom: theme.spacing(.75),
    color: theme.palette.common.white,
    fontWeight: 300,
    [theme.breakpoints.up('md')]: {

    }
  },
  bottomImage:{
  	// background: theme.palette.common.black,
  	background: '#323b4d',
  },
  icon: {
  	color: theme.palette.secondary.main,
  	fontSize: '1.75em'
  },
  divider: {
  	// background: '#343b4d',
  	background: theme.palette.common.black,
  },
}));


export default function CarrierOverview(props) {
	const classes = carrierOverviewStyles();

	const list = [
		'Has a team of reliable adjusters who can go to work immediately?',
		'Has the latest technology in place to document and process claims?',
		'Offers cost-effective solutions?',
		'Can turn claims around quickly?',
		'Will take the stress off your internal team?',
		'Can make the claims process seamless for policyholders?',
	];

	const Qualifications = (props) => {

		const filterList = () => {
			return props.side === 'left' 
				? list.filter((text, index) => index++ % 2 !== 0 )
				: props.side === 'right'
					? list.filter((text, index) => index++ % 2 === 0 )
					: list
		}

		return (
    <List component="ul" aria-label="list">
    	{	filterList().map((text, index) => (
    		<React.Fragment key={index}>
    			<ListItem className={classes.listItem}>
    				<ListItemIcon>
    					<CheckCircleIcon className={classes.icon} />
    				</ListItemIcon>
	          <ListItemText>
	          	<Typography variant='h6' className={classes.listText}>
								{ text }       
							</Typography>
	          </ListItemText>
	        </ListItem>
	        <Divider light={true} className={classes.divider} />
	      </React.Fragment>
    		)) }
     </List>
    )
	}
	
	return (<>
		<div style={{ background: '#323b4d' }}>
			<div className={classes.bottomImage}>
		    <div className={classes.imageWrapper}>
		    	<picture>
		    		<source srcSet='images/we-get-it-2.png' media="(min-width: 800px)" />
		    		<img srcSet="/images/we-get-it-m.jpg" className={classes.weGetItImage} alt='We Get It' />
		    	</picture>
		  	</div>
		  </div>

			<div className={classes.root}>

			    <Grid container spacing={4} className={classes.solutions} style={{ alignItems: 'center' }}>
			      <Grid item xs={12} md={10} xl={8}>
			      	<Typography variant='h3' align='center' className={classes.weGetIt} >
			        	We Get It.
			        </Typography>

			        <Divider style={{ margin: '0 auto', width: '10%', height: '5px', background: '#43D6A7'}}/>

			        <Typography component='p' align='center' className={classes.heading}>
			        	We know you’re working hard to be a trusted insurance carrier. But how do you manage the stress and significant challenges that come when high claims volumes exceed your internal capacity?
			        </Typography>
			      </Grid>
			    </Grid>

			    <Grid container spacing={3} style={{ paddingTop: 24, paddingBottom: 24, justifyContent: 'center' }}>
			    	<Grid item xs={12} md={7} xl={8}>
			        <Typography variant='h4' align='center' className={classes.listHeading}>
			        	Are you worried about finding someone who...
			        </Typography>
			      </Grid>
			    </Grid>

			    <Grid container spacing={0} className={classes.solutions} > 
			    	<Grid item xs={12}sm={5} md={5} xl={5}>
			    		<div className={ classes.list }>
			        	<Qualifications side='left' />
			        </div>
			      </Grid>
			      <Grid item xs={12} sm={5} md={5} xl={5}>
			      	<div className={ classes.list }>
			        	<Qualifications side="right" />
			        </div>
			      </Grid>
			    </Grid>

			  	<Grid container spacing={4} className={classes.solutions}>
			    	<Grid item xs={12} md={10} xl={7}>
			        <Typography variant='h5' align='center' className={classes.heading}>
			        	At Claim Consultant Group, we’ll leverage our considerable experience to meet your policyholders’ needs.
			        </Typography>
		      	</Grid>
			 
			      <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', }}>
			      	{ props.callToAction }
			      </Grid>
			      <Grid item xs={12} style={{display: 'flex', justifyContent: 'center',  marginBottom: '3em'}}>
			      <Link to="/services" style={{ color: '#f7f7f7',  }}>
							<span>Explore Our Services</span>
						</Link>
						</Grid>

			      {/*
			      <Grid item xs={12} md={10} xl={7} style={{ marginBottom: '3em', marginTop: '2em', color: '#' }}>
			       <Typography component='p' align='center' style={{ color: '#f7f7f7' }}>
			        	How strong is our team? We processed over <span style={{ color: '#43D6A7', fontWeight: '900' }} >16,000 claims</span> during Hurricane Harvey alone.
			        </Typography>
			      </Grid>
			      */}
			    </Grid>

			  </div>
		 </div>
  </>)
}