import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const ctaStyles = makeStyles((theme, props) => ({
	button: {
		fontWeight: 900,
	}
}))

export default function CallToAction(props) {
	const classes = ctaStyles()

	return (
		<Button 
			className={classes.button} 
			onClick={props.clicked} 
			style={props.styling} 
			size={props.size || 'large'} 
			disableElevation={props.disableElevation || false}
			variant="contained" color="primary"
			>
				Let's Talk.
			</Button>
	)
}