import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const footerStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		// background: '#5390cd',
		// background: '#343b4d',
		background: theme.palette.common.black,
		// background: theme.palette.common.white,
		padding: 24,
	},
	flex: {display: 'flex', alignItems: 'center', justifyContent: 'center'},
	footerContainer: {
		display: 'flex',
		flexDirection: 'column-reverse',
		[theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
		justifyContent: 'center',
		alignItems: 'center',
	},
	footerColumn: {
		width: '100%'
	},
	footerLogo: {
		width: '55%',
		height: 'auto',
		display: 'block',
		margin: '0 auto'
	},
	footerCenter: {
		color: '#f7f7f7',
		fontWeight: 900,
	},
	footerLink: {
		color: [theme.palette.primary.main]
	},
	verticalDivider: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			margin: '3em',
		}
	},
	copyright: {
		fontWeight: 300,
		fontSize: '.75em',
		color: '#f7f7f7'
	},
	 actions: {
    display: 'flex',
    paddingRight: '1rem',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
    '& a': {
    	color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
}))



export default function Footer(props) {
	let classes = footerStyles();

	const currentYear = () => {
		const now = new Date();
		return `copyright © ${ now.getFullYear() } | Claim Consultant Group LLC`
	}

	  const Actions = () => {
    return (
      <div className={classes.actions}>
        <a href="tel:8886451480" style={{ textAlign: 'center', textDecoration: 'none'}}><span className={classes.phone}>(888) 645-1480</span></a>
        <div className={ classes.flex }>
        <a href="https://www.facebook.com/ClaimConsultantGroup">
          <FacebookIcon className={classes.icons} />
        </a>
        <a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGLZkTubWrFLAAAAXIEGvyoP6HgOBgyfIL0OcdRf0COb1lKxXmSN_5qGSTLNRmQEsJ5eJdGn-rrxjOzHKuSkhZzL4KQnZnL1Zg6q7Vd2eF8fWTF2BiwqeGIfj8kVutPVfMPfIc=&originalReferer=https://claimconsultantgroup.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fclaim-consultant-group-llc%3Ftrk%3Dtyah%26trkInfo%3DtarId%253A1423599943810%252Ctas%253Aclaim%2520consultantgroup%252Cidx%253A1-1-1" >
          <LinkedInIcon className={classes.icons} />
        </a>
        </div>
      </div>
    )
  }

	return (
  <div className={classes.root}>
  	<Grid container spacing={4} className={classes.footerContainer}>

	  	<Grid item xs={12} md={3}>
  			<img src='/images/logo.svg' className={classes.footerLogo} alt="Claim Consultant Group"/>
  			<Divider style={{ margin: '1em' }}/>
  			<Actions />
  			<Typography component='p' align="center" className={classes.copyright} >
  				{ currentYear() }
  			</Typography>
  		</Grid>

  		<Divider orientation="vertical" flexItem className={classes.verticalDivider} />
 
  		<Grid item xs={12} md={3} style={{ width: '100%' }}>
				<List>
	        {props.routes.map((route, index) => (
	        	<div key={index}>
	          <ListItem button component={Link} to={route.path} alignItems="flex-start">
	            <ListItemText primary={route.label} className={classes.footerLink}/>
	          </ListItem>
	          <Divider />
	          </div>
	        ))}
      	</List>
  		</Grid>

  	</Grid>
  </div>
	)
}