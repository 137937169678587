import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Close from '@material-ui/icons/Close';

	const panelStyles = makeStyles((theme, props) => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		panel: {
			background: props => props.idx % 2 === 0 ? '#efefef' : '#e7e7e7',
			// padding:  '0 2rem',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			margin: '2rem 0',
			[theme.breakpoints.up('md')]: {
				justifyContent: 'center',
				alignItems: 'center',
				// flexDirection: 'column-reverse',
				// flexDirection: props => props.idx % 2 === 0 ? 'row': 'row-reverse',
			}
		},
		title: {
			fontSize: '2.5em', fontWeight: 600, color: '#343b4d',
			[theme.breakpoints.up('md')]: {
				// width: '60%',			
			}
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			padding: '0 2rem',
			width: '80%',
			[theme.breakpoints.up('md')]: {
				alignItems: 'center',
				flexGrow: 1,
				width: '60%',
			},
		},
		hiddenComponent: {
			width: '100%',
			[theme.breakpoints.up('md')]: {
				padding: '0 2rem',
			},
		},
		twoColumn: {
				[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row',
				'& p': {
					padding: '0 1rem',
					width: props => props.hasTwoParagraphs ? '50%' : '100%',
				}
			}
		},
		button: {
			// width: '80%',
			marginBottom: 32,
			[theme.breakpoints.up('md')]: {
				// width: '1%',
				marginTop: 32,
			}
		},
		icon: {
			color: theme.palette.primary.main,
			padding: '0 2rem',
			// display: 'flex',
			// justifyContent: 'flex-start',
			// marginTop: '2rem',
			'& svg': {
				fontSize: '4rem',
				marginTop: '2rem',
			},
		},
		photo: {
			width: '100%',
			minHeight: 'auto',
			border: 'none',
			[theme.breakpoints.up('md')]: {
				marginTop: '2rem',
				width: 'auto',
				height: '50vh',
				border: '1rem solid #fff',
			}
		}
	}));

export default function ExpandablePanel(props) {
	let classes = panelStyles(props);
	let paragraphs = props.copy ? props.copy.match(/([^\r\n]+)/gm) : [];

	const [state, setState] = React.useState({show: false});


	const getPoints = (props) => (
		"0,0 100,0 " + props.focalPoint + ",5 0,0"
	)

	const SvgBG = (props) => (
		<svg fill={props.fill} transform={props.transform} viewBox="0 0 100 5" preserveAspectRatio="none" width="100%" height="100%">
      <polygon points={getPoints(props)}  strokeWidth=".25" strokeLinecap="round"></polygon>
      {/*<line x1="40" x2="120" y1="100" y2="100" stroke="red" stroke-width="20" stroke-linecap="round"/>*/}
    </svg>
	)

	const toggle = () => event => {
		setState({show: !state.show});
	}

	// console.log(props.copy ? paragraphs : null);

	return (
		<div className={classes.root}>
			<div id={props.id} className={classes.panel}>
				<div className={classes.image}>
					<picture>
		    		<source srcSet={props.photo} media="(min-width: 1000px)" />
		    		<img srcSet={props.mobilePhoto} alt={props.alt} className={classes.photo}/>
	    		</picture>
    		</div>
				<div className={classes.icon}>{ props.icon }</div>
				<div className={classes.content}>
					<h1 className={classes.title}>{props.title}</h1>
					<div className={classes.twoColumn}>
						{ 
							props.copy && paragraphs.length > 1 
								? paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>)
								: <p>{paragraphs[0]}</p>
						}
					</div>

					<div className={classes.hiddenComponent} style={ state.show ? { display: 'block' } : { display: 'none' } }>
						{props.hiddenComponent}
					</div>

					<Button variant="contained" color={state.show ? 'default' : 'primary'} onClick={toggle()} endIcon={ state.show ? <Close /> : <ExpandMore /> }  className={classes.button} fullWidth={false} disableElevation>{state.show ? 'show less' : props.btnText}</Button>
				</div>
			</div>

		<Grid container spacing={0}>
			<Grid item xs={12} style={ { marginTop: '-2em' } }>
				<SvgBG fill={props.idx % 2 === 0  ? '#efefef' : '#e7e7e7' } transform={ 'rotate(0)'} focalPoint={50} />
			</Grid>
		</Grid>
	</div>
	)
}