import React from 'react'
import { Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Divider, } from '@material-ui/core/'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';


const callCenterStyles = makeStyles((theme) => ({
	root: {
		//
	},
	content: {
		display: 'flex', justifyContent: 'center',
	},
	text: {
		color: [theme.palette.common.black],
		padding: '1rem 2rem',
		lineHeight: '1.75em',
	},
	title: {
		fontSize: '3rem', fontWeight: 900, padding: '1rem', color: [theme.palette.navy.main],
		[theme.breakpoints.up('md')]: {
			fontSize: '4rem', fontWeight: 900, padding: '4rem 0 4rem 0', color: [theme.palette.navy.main],
		}
	},
	blockQuote: {
		background: '#5390cd', color: '#343b4d', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4rem 0 1rem 0',
		[theme.breakpoints.up('md')]: {
			margin: '6rem 0 0 0'
		}
	}
}))

export default function (props) {
	const classes = callCenterStyles();

	// const SvgBG = (props) => (
	// 	<svg fill={props.fill} viewBox="0 0 100 5" preserveAspectRatio="none" width="100%" height="100%">
 //      <polygon points={getPoints(props)}  strokeWidth=".25" strokeLinecap="round"></polygon>
 //    </svg>
	// )

	// const getPoints = (props) => (
	// 	"0,0 100,0 " + props.focalPoint + ",5 0,0"
	// )


	const callCenterList = () => ([
		{ link: '/', text: 'First Notice of Loss calls' },
		{ link: '/', text: 'Weather closures' },
		{ link: '/', text: 'Scheduled meetings' },
		{ link: '/', text: 'Holiday closures' },
		{ link: '/', text: 'Custom web form reports'},
		{ link: '/', text: 'PDF notifications'},
	])

	

	return (
		<div className={classes.root}>
			<Grid container spacing={0}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0 }}>	
			<Grid item xs={12} md={7}>
			<Typography variant='h5' style={{ fontWeight: 600, color: '#343b4d' }}>
				Our Call Center Services Include:					
			</Typography>
				<List component="ul" aria-label="list">
	    	{	callCenterList().map((item, index) => (
	    		<React.Fragment key={index}>
	    			<ListItem className={classes.listItem}>
		    			<ListItemIcon>
		    				<CheckCircleIcon color="primary"/>
	  					</ListItemIcon> 
		          <ListItemText>
		          	<Typography variant='h6' style={{ color: '#323b4d' }} className={classes.listText}>
									{ item.text }  
								</Typography>
		          </ListItemText>
		        </ListItem>
		      <Divider light={true} className={classes.divider} />
		      </React.Fragment>
	    		))}
     		</List>
			</Grid>
  	</Grid>
  	<Grid container spacing={0} style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
			<Grid item xs={12}>
				<Typography variant="h5">Every phone call matters. We’re here to staff your phones when you’re away.</Typography>
			</Grid>
		</Grid>
	</div>
	)
}