import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';



const useStyles = makeStyles(theme => ({
  nav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '.15rem 0',
    background: '#343b4d',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    }
  },
  'a': {
    cursor: 'pointer',
  },
  logo: {
    paddingLeft: '1rem',
    height: '10px',
    width: 'auto',
  },
  actions: {
    display: 'none',
    paddingRight: '1rem',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    }
  },
  phone: {
    fontWeight: 300, color: '#f7f7f7'
  },
  icons: {
    color: theme.palette.common.white,
    paddingLeft: '.25rem'
  }
}));

export default function Top(props) {
  const classes = useStyles();
  // console.log(props.isMobile)

  const Actions = () => {
    return (
      <div className={classes.actions}>
        <span className={classes.phone}>(888) 645-1480</span>
        <a href="https://www.facebook.com/ClaimConsultantGroup">
          <FacebookIcon className={classes.icons} />
        </a>
        <a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGLZkTubWrFLAAAAXIEGvyoP6HgOBgyfIL0OcdRf0COb1lKxXmSN_5qGSTLNRmQEsJ5eJdGn-rrxjOzHKuSkhZzL4KQnZnL1Zg6q7Vd2eF8fWTF2BiwqeGIfj8kVutPVfMPfIc=&originalReferer=https://claimconsultantgroup.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fclaim-consultant-group-llc%3Ftrk%3Dtyah%26trkInfo%3DtarId%253A1423599943810%252Ctas%253Aclaim%2520consultantgroup%252Cidx%253A1-1-1" >
          <LinkedInIcon className={classes.icons} />
        </a>
      </div>
    )
  }

  return (
    <div className={classes.nav}>
        <a href="/">
          <img src='/images/logo-text.svg' className={classes.logo} alt="Claim Consultant Group llc" />
        </a>
        <Actions />
    </div>
  );
}