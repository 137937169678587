import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Divider, } from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

const solutionPlanStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		// paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		justifyContent: 'center'
	},
	icon: {
		color: [theme.palette.primary.main]
	},
	listText: {
		fontSize: '1em',
		[theme.breakpoints.up('md')]: {
			fontSize: '1.25em'
		}
	},
	topImage: {
		width: '100%',
		height: 'auto',
		display: 'block'
	}
}))

const planList = () => {
	return [
		{ title:'Schedule a call.', text: 'It helps us understand your specific challenges. We’re willing to work hard to earn your business.'},
		{ title: 'Get your customized Claim Solution Plan.', text: 'We’ll recommend the right combination of staffing and technology to seamlessly process your claims.'},
		{ title: 'Relax.', text: 'Rest assured that you have a committed partner providing you with the quality and exceptional service you and your policyholders deserve.'},
	]
}

export default function SolutionPlan(props)  {
	const classes = solutionPlanStyles()
	const pList = planList();

	return (<>
		<div>
			<picture>
	      <source srcSet='/images/plan.jpg' media="(min-width: 800px)" />
		    <img src='/images/plan-m.jpg' className={classes.topImage} alt='Lets Talk' />
	    </picture>
		</div>
		<div style={{paddingLeft: 24, paddingRight: 24, background: '#ededed'}}>
			<Grid container spacing={0} className={classes.root}>
				<Grid item xs={12} md={8} xl={7}>
					<div>
					<Typography variant='h4' align='center'>Let’s Create Your Claim Solution Plan</Typography>
					<List component="ul" aria-label="list">
		    	{	pList.map((item, index) => (
		    		<React.Fragment key={index}>
		    			<ListItem className={classes.listItem}>
		    			{ ! props.isMobile 
		    				? <ListItemIcon>
		    						<ArrowForwardIos className={classes.icon} />
		    					</ListItemIcon>
	    					: null
		    			}
			          <ListItemText>
			          <Typography variant='h6' className={classes.listText}>
										<span style={{ fontWeight: 900, fontSize: '1.25em', color: '#323b4d' }}>{ item.title }</span>
									</Typography>
			          	<Typography variant='h6' className={classes.listText}>
										{ item.text }       
									</Typography>
			          </ListItemText>
			        </ListItem>
			        <Divider light={true} className={classes.divider} />
			      </React.Fragment>
		    		)) }
		     </List>
		     </div>
				</Grid>
			</Grid>	

			<Grid container spacing={4} className={classes.root} >
				<Grid item xs={12} md={8} xl={7} className={classes.root}>
					{ props.callToAction }
				</Grid>
			</Grid>

			<Grid container spacing={4} style={{display: 'flex', justifyContent: 'center'}} >
				<Grid item xs={12} md={8} xl={5} className={classes.root}>
					<Typography variant='h6' align='center' style={{ paddingBottom: '4em' }}>
		    		Now, you can stop worrying about how to support your policyholders. You’ll have peace of mind knowing your policyholders’ claims are handled efficiently and compassionately.
		     </Typography>
	      </Grid>
			</Grid>
		</div>
	</>)
}