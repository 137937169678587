import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
    overrides: {
      MuiAppBar: {
      	root: {
      		background: '#343b4d',
      		color: '#f7f7f7'
      	}
      }
    },
    typography: {
    	fontFamily: [
	      '"Nunito Sans"',
	      '"Helvetica Neue"',
	      'Arial',
	      'sans-serif',
	      '"Apple Color Emoji"',
	      '"Segoe UI Emoji"',
	      '"Segoe UI Symbol"',
	    ].join(','),
  	},
    palette: {
  	common: { black: '#1d212b', white: '#f7f7f7' },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#5390cd',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#43D6A7',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#1d212b',
    },
    navy: {
      darkest: '#1d212b',
      darker: '#252a37',
      main: '#343b4d',
      
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export default theme

// const palette = {
//   primary: { main: '#5390cd' },
//   secondary: { main: '#43D6A7' },
//   dark: {main: '#43D6A7'},
// };
// const themeName = 'CCG Branding';

// export default createMuiTheme({ palette, themeName });