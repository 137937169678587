import React from 'react'

export default function Covid() {
	return (<React.Fragment>
		<p>As the novel coronavirus (COVID-19) begins to have an increased impact on our communities, we feel it’s important to connect directly with you to share more about the proactive steps we’re taking to address several business concerns. The health and safety of our adjusters and insured’s will always be our top priority and this is a rapidly evolving situation that we will continue to monitor. As of earlier today, the World Health Organization (WHO) has officially classified the COVID-19 outbreak as a pandemic. We want to ensure the continuity of business operations for all of our clients and their policy-holders. We must all work together as a community to take preventive steps identified by the Center for Disease Control (CDC) and World Health Organization (WHO).</p>
		<p>Please stay aware of the latest information on the COVID-19 outbreak, available on the Center for Disease Control and World Health Organization websites and through your national and local public health authority.</p>
		<p>We ask that all adjusters be mindful of your health and protect others by doing the following:</p>
		<ul>
	    <li>Stay home when you are sick, signs and symptoms include respiratory symptoms and include fever, cough and shortness of breath. [1]</li>
	    <li>Utilize hand sanitizer/antibacterial wipes that contain at least 60% alcohol prior to entering an Insured’s residence and immediately after leaving. [2]</li>
	    <li>Wash your hands frequently with warm, soapy water for at least 20 seconds. [3]</li>
	    <li>Cover your nose and mouth with a flexed elbow or disposable tissue when coughing and sneezing. [4]</li>
	    <li>Avoid close contact with anyone that has a fever, cough or respiratory-likesymptoms.[5]</li>
	    <li>Do not shake hands, if you do, wash or use hand sanitizer immediately. [6]</li>
	    <li>Clean and disinfect frequently touched surfaces daily, including cell phones and keyboards.[7]</li>
	    <li>When making first contact with insured's, ask if anyone in the home has any symptoms.</li>
	  </ul>
	</React.Fragment>)
}