import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/ToolBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

function a11yProps(route) {
  return {
    id: `nav-item-${route.label}`,
    'aria-controls': `nav-route-${route.label}`,
  };
}

function TabLink(props) {
  return (
    <Tab
      component={Link}
      onClick={event => {
        // event.preventDefault();
      }}
      {...props}
    />
  );
}

const navStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // backgroundColor: theme.palette.background.paper,
    width: '50%'
  },
  tabs: {
    fontWeight: 600
  },
  logo: {
    height: '25px',
    width: 'auto',
    paddingLeft: '1em',
  },
  menuButton: {
    marginLeft: theme.spacing(0),
  },
  nav: {
    // background: '#f7f7f7',
    backgroundColor: '#e5e7eb',
    // borderBottom: '1px solid #efefeb',
    boxShadow: 'none',
  },
  desktopNav: {
    display: 'flex', alignItems: 'center', justifyContent: 'space-between'
  },
  mobileNav: {
    background: '#e5e7eb',
  },
  title: {
    fontWeight: 600,
  }
}));

export default function NavTabs(props) {
  const classes = navStyles();
  const location = useLocation();

  const getValue = () => {
    return routeIsInNavigation(location.pathname)
      ? location.pathname
      : false
  }

   const routeExists = (path) => { 
    let match = props.routes.find(route => route.path === path)
    return match 
      ? match
      : {label: '404 Not Found', path: 0}
    // return match ? true : false;
  }

  const routeIsInNavigation = (path) => {
    let route = props.routes.find(route => route.path === path)
    return route.inNav;
  }

  const [value, setTabValue] = React.useState(getValue);
  const [state, setState] = React.useState({show: false});

  const handleChange = (event, newValue) => {
    if (routeIsInNavigation(newValue)) setTabValue(newValue) 
    window.scrollTo(0,0)
  }

  // if (routeExists(value)) {
  //   handleChange(null, value)
  // }

   let currentRoute = routeExists(location.pathname)

  const toggleDrawer = () => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, show: !state.show });
  };

  // handles active tab assignment when not using main navigation bar.
  if (location.pathname !== value) handleChange(null, location.pathname);

  const mobileNav = () => (
    <div
      className={classes.mobileNav}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {props.routes.filter(route => route.inNav === true).map((route, index) => (
          <div key={index}>
          <ListItem button key={index} component={Link} to={route.path} onClick={e => window.scrollTo(0,0)} {...a11yProps(route)}>
            <ListItemText primary={route.label} />
          </ListItem>
          <Divider />
        </div>
        ))}
      </List>
    </div>
  )

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        color='default'
        className={classes.nav}
        >
        { props.isMobile 
          // if mobile show Mobile Nav...
          ? <ToolBar>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
             <a href="/" style={{ cursor: 'pointer' }} >
              <img src='/images/logo-icon.svg' className={classes.logo}  alt="Claim Consultant Group llc" style={{ paddingLeft: 0 }}/>
            </a>
            <Typography variant="h6" className={classes.title}>
              { currentRoute.label === 'Home'  ? '' : currentRoute.label }
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open navigation"
              className = {classes.menuButton}
              onClick={toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
            </div>
            </ToolBar>
          //otherwise show the desktop navigation...
          :
          <div className={classes.desktopNav}>
            <a href="/" style={{ cursor: 'pointer', flexShrink: 0 }} >
              <img src='/images/logo-icon.svg' className={classes.logo}  alt="Claim Consultant Group llc" />
            </a>
            <Tabs
              variant="standard"
              value={value}
              onChange={handleChange}
              aria-label="Navigation"
              indicatorColor='primary'
              style={{flexShrink: 1}}
            >
            { 
              props.routes.filter(route => route.inNav === true).map((route, index) => (
               <TabLink key={index} value={route.path} label={route.label} to={route.path} className={classes.tabs} {...a11yProps(route)} />
              )) 
            }
          </Tabs>
            <div style={{flexShrink: 0}}>
              { props.callToAction }
              <Button variant="contained" size="small" component={Link} to='/adjusters' disableElevation style={{ fontWeight: 600, color: '#777' }}>Adjusters</Button>
           </div>
          </div>
        }

      </AppBar>
      <Drawer anchor="top" open={state.show} onClose={toggleDrawer()}>
        {mobileNav()}
      </Drawer>
    </React.Fragment>
  );
}
