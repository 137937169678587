import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded';
import WavesRoundedIcon from '@material-ui/icons/WavesRounded';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';
import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

const servicesStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '#1d212b',
	},
	solutions: {
    flexGrow: 1,
   	marginTop: '6em',
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    }
  },
  button: {
  	fontWeight: 900,
  	marginTop: 25,
  	marginBottom: theme.spacing(4),
  	// color: '#fff'
  },
  heading: {
  	color: '#343b4d',
  	fontWeight: 600
  },
  text: {
  	color: '#899dd2',
  	textAlign: 'center',
  	fontSize: '1.25rem',
  },
  sectionTitle: {
  	color: theme.palette.common.white,
  	textTransform: 'uppercase',
  	fontWeight: 600,
  	paddingTop: theme.spacing(4),
  	paddingBottom: theme.spacing(2),
  	letterSpacing: '.1rem',
  },
  title: {
  	color: '#f7f7f7',
  	fontWeight: '900',
  	paddingBottom: theme.spacing(2),
  	background: 'linear-gradient(to right, #5390cd, #43D6A7)',
  	'-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  items: {
  	display: 'flex', 
  	flexDirection: 'column',
  	alignItems: 'center', 
  	justifyContent:'center', 
  	margin: '0',
	 	[theme.breakpoints.up('md')]: {
	 		margin: '3rem 0'
	 	}
  },
  item: {
  	display: 'flex',
  	margin: '0 1rem 3rem 1rem',
  	[theme.breakpoints.up('md')]: {
   		margin: '0 3rem 3rem 3rem',
  	}
  },
  itemIcon: {
  	display: 'flex',
  	justifyContent: 'center',
  	alignItems: 'center',
  	margin: '0 1rem 0 1rem',
  	minWidth: '2.5rem',
  	height: '2.5rem',
  	backgroundColor: theme.palette.primary.main,
  	borderRadius: '.5rem',
  	[theme.breakpoints.up('md')]: {
  		minWidth: '3rem',
			height: '3rem',
  	},
  	animation: `$fadeIn 1.5s ${theme.transitions.easing.easeOut}`,
  },
  link: {
    textDecoration: 'none'
  },
  '@keyframes slideFadeIn' : {
  	'15%, 100%': {
    	opacity: 0,
    	transform: 'translate(-35px, 0)',
  	},
  	'0%': {
    	opacity: 0,
  	},
  	'100%': {
    	opacity: 1,
    	transform: 'translate(0, 0)',
  	}
  },
  '@keyframes delayedSlideFadeIn' : {
  	'35%, 100%': {
    	opacity: 0,
    	transform: 'translate(-35px, 0)',
  	},
  	'0%': {
    	opacity: 0,
  	},
  	'100%': {
    	opacity: 1,
    	transform: 'translate(0, 0)',
  	}
  },
  '@keyframes fadeIn': {
	    "from": {
	      opacity: 0,
	      transform: "translateY(-50%) rotateZ(-10deg)",
	    },
	    "to": {
	      opacity: 1,
	      transform: "translateY(0) rotateZ(0)",
	    }
	  },
  itemTitle: {
  	color: '#f7f7f7',
  	fontSize: '1.5rem',
  	fontWeight: 600,
  	marginBottom: '.5rem',
  	animation: `$slideFadeIn 1.5s ${theme.transitions.easing.easeOut}`,
    textDecoration: 'none',
  },
 	itemText: {
 		color: '#899dd2',
 		fontWeight: 300,
 		fontSize: '1.25rem',
 		animation: `$delayedSlideFadeIn 1s ${theme.transitions.easing.easeOut}`,
    textDecoration: 'none',
 	},
}));

export default function Services(props) {
	const classes = servicesStyles();

	return (
		<div className={classes.root}>
			<Grid container spacing={0} style={{ display: 'flex', justifyContent:'center', padding: '0 1rem 0 1rem'}}>
				<Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>
					<Typography variant="h5" className={classes.sectionTitle}>Services</Typography>
				</Grid>
				<Grid item xs={12} md={7} style={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>
					<Typography variant="h3" className={classes.title}>Our Expertise</Typography>
				</Grid>
				<Grid item xs={12} md={7} style={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>
					<Typography variant="h5" className={classes.text}>When insurance carriers have increased claim demand, we provide seamless service to policyholders. We help you maintain business as usual and work to exceed your policyholders’ expectations.</Typography>
				</Grid>
			</Grid>

			<Grid container spacing={0} style={{ display: 'flex',  justifyContent:'center', margin: '3rem 0 0 0'}}>
				<Grid item xs={12} md={6} className={classes.items}>

          <div className={classes.item}>
            <div className={classes.itemIcon} data-has-animation>
            <Link to="services#tpa"><SupervisorAccountIcon style={{ color: '#f7f7f7' }} /></Link>
            </div>
            <div>
            <Link to="services#tpa" className={classes.link}>
              <Typography variant="h5" className={classes.itemTitle} data-has-animation>Third Party Administration (TPA)</Typography>
            </Link>
            <Link to="services#tpa" className={classes.link}>
              <Typography component="p" className={classes.itemText} data-has-animation>No two TPA programs are the same. We will work with you to customize a program to fit your needs, from claim handling to claim system integration.</Typography>
            </Link>
            </div>
          </div>

          <div className={classes.item}>
            <div className={classes.itemIcon} data-has-animation>
            <Link to="services#staff-augmentation"><GroupAddRoundedIcon style={{ color: '#f7f7f7' }} /></Link>
            </div>
            <div>
              <Link to="services#staff-augmentation" className={classes.link}>
                <Typography variant="h5" className={classes.itemTitle} data-has-animation>Staff Augmentation</Typography>
              </Link>
              <Link to="services#staff-augmentation" className={classes.link}>
                <Typography component="p" className={classes.itemText} data-has-animation>We provide experienced adjusters to work from your office or remotely. We help you quickly assist your policyholders and review claims during a high-volume event.</Typography>
              </Link>
            </div>
          </div>

				</Grid>
			<Grid item xs={12} md={6} className={classes.items}>
					
					 <div className={classes.item}>
            <div className={classes.itemIcon} data-has-animation>
              <Link to="services#daily"><WbSunnyRoundedIcon style={{ color: '#f7f7f7' }} /></Link>
            </div>
            <div>
            <Link to="services#daily" className={classes.link}>
              <Typography variant="h5" className={classes.itemTitle} data-has-animation>
                Daily Claims
              </Typography>
            </Link>
             <Link to="services#daily" className={classes.link}>
              <Typography component="p" className={classes.itemText} data-has-animation>
                Claim Consultant Group provides customized, nationwide claim management services to meet our business partners’ specialized needs.
              </Typography>
            </Link>
            </div>
          </div>

          <div className={classes.item}>

              <div className={classes.itemIcon} data-has-animation>
                 <Link to="services#catastrophe"><WavesRoundedIcon style={{ color: '#f7f7f7' }} /></Link>
              </div>
            <div>
              <Link to="services#catastrophe" className={classes.link}>
                <Typography variant="h5" className={classes.itemTitle} data-has-animation>
                  Catastrophe Claims
                </Typography>
              </Link>
              <Link to="/services#catastrophe" className={classes.link}>
                <Typography component="p" className={classes.itemText} data-has-animation >
                  A catastrophic event can disrupt your policyholder’s life. Don’t let it disrupt the claims standard they have come to expect of you as their trusted carrier.
                </Typography>
              </Link>
            </div>
          </div>			

				</Grid>

        <Grid item xs={12} md={6} className={classes.items} style={{ marginTop: 0 }}>
          <div className={classes.item}>
            <div className={classes.itemIcon} data-has-animation>
            <Link to="services#call-center"><HeadsetMicRoundedIcon style={{ color: '#f7f7f7' }} /></Link>
            </div>
            <div>
            <Link to="services#call-center" className={classes.link}>
              <Typography variant="h5" className={classes.itemTitle} data-has-animation>Call Center</Typography>
            </Link>
            <Link to="services#call-center" className={classes.link}>
              <Typography component="p" className={classes.itemText} data-has-animation>Need someone to cover for you during lunch, weather closures, or holidays? We’ll make sure you never miss a call.</Typography>
            </Link>
            </div>
          </div>
        </Grid>

			</Grid>

	  </div>
  )
}