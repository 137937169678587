import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const pageStyles = makeStyles((theme, props) => ({
	root: {
		display: 'flex',
		width: '100%',
		minHeight: '80vh',
		flexDirection: 'column',
		alignItems: 'center',
		// [theme.breakpoints.up('md')]: {
		// 	minHeight: '80vh',
		// }
	},
	header: {
		width: '100%',
		height: '15em',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundSize: 'cover',
		backgroundImage: props => props.headerImage ? `url(${props.headerImage})` : 'none',
		backgroundColor: props => !props.headerImage ? '#ccc' : 'none',
		backgroundPosition: 'center center',
		[theme.breakpoints.up('md')]: {
			height: '20em',
		}
	},
	title: {
		fontSize: '2.5em',
		color: [theme.palette.common.white],
		textShadow:  '2px 2px 2px black',
	},
	container: {
		width: '90%',
		padding: '4rem 0',
		[theme.breakpoints.up('md')]: {
			width: '60%'
		}
	}
}))

export default function Page(props) {
	const classes = pageStyles(props);

  return (
  <div className={classes.root}>
  	<div className={classes.header}>  
  		<h1 className={classes.title}>{props.title}</h1>
		</div>
   <div className={classes.container}>
      {props.content}
    </div>
  </div>
  )
}