import React from 'react'
import { Button } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { makeStyles } from '@material-ui/core/styles';
import Form from './Form';

// const callToActionFormStyles = makeStyles((theme, props) => ({
// 	input: {
// 		padding: '.5rem 0'
// 	},
// 	icon: {
// 		paddingRight: '.5rem',
// 		color: theme.palette.navy.main
// 	}
// }))

 export default function CallToActionForm(props) {
 	// const classes = callToActionFormStyles(props);

 	const [btnText, setBtnText] = React.useState(props.btnText);
 	const [showForm, setShowForm] = React.useState(false);
 	const [showMessageBox, setShowMessageBox] = React.useState(false);

	const [formData, setFormData] = React.useState({
 		message: `What's on your mind...`,
 		email: '',
 		name: '',
 		company: '',
 	});

 	const required = () => ([formData.name, formData.email, formData.company]);

	const handleFormChange = (event) => {
 		const target = event.target
 		setFormData({...formData, [target.id]: target.value});
 		valid() 
 			? setShowMessageBox(true) 
 			: setShowMessageBox(false);
 	}

 	const toggleForm = () => {
 		setShowForm(!showForm)
 		updateButtonText()
 	};

 	const valid = () => {
 		return required().every(value => value !== '')
 	}

 	const resolveDialog = () => {
 		if(valid() && showForm) submitFormData()
 		if (!showForm) props.clicked()
 	}

 const request = () => ({
	headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": 'application/json'
  },
  method: 'POST',
  body: JSON.stringify(formData),
  mode: 'cors'
})

 const submitFormData = () => {
 		window.fetch('https://roster.ccgops.net/api/call-to-action/email', request())
		.then(response => response.text())
		.then(data => console.log(data))
		.catch(error => console.log(error));
 		props.clicked()
 }

 	const updateButtonText = () => (!showForm ? setBtnText('Send') : setBtnText(props.btnText))

 	 	return (
 	 		<Dialog id="call-to-action-form" open={props.show} onClose={props.handleClose} fullWidth aria-labelledby="call-to-action-title">
        <DialogTitle id="call-to-action-title"><span style={{ fontWeight: 600 }}>{props.title}</span></DialogTitle>
        <DialogContent>
        	<DialogContentText>{props.description}</DialogContentText>
        	{ showForm ? <Form data={formData} handleFormChange={handleFormChange} showMessageBox={showMessageBox} /> : null }
        </DialogContent>
        <DialogActions>
          <Button onClick={props.clicked} size="small" color="primary" disableElevation>
            Cancel
          </Button>
          <Button onClick={toggleForm} variant="contained" size="small" color="secondary" disableElevation>
            {showForm ? 'Call Us' : 'Email Us' }
          </Button>
          <Button component='a' href={ showForm ? '#' : "tel:8886451480"} onClick={resolveDialog} size="small" variant="contained" color="primary" disableElevation>
            {btnText}
          </Button>
        </DialogActions>
      </Dialog>
 		)
 }