import React from 'react'
import { Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Divider, } from '@material-ui/core/'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';


const tpaStyles = makeStyles((theme) => ({
	root: {
		// background: '#e5e7eb',
		// background: '#f0f0f0',
		// color: '#e5e7eb'
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: { 
		fontSize: '3rem', fontWeight: 900, padding: '3rem 1rem', color: '#343b4d',
		[theme.breakpoints.up('md')]: {
			fontSize: '4rem', fontWeight: 900, padding: '4rem 0 2rem 0', color: '#343b4d',
		}
	},
	text: {
		// padding: '1rem 2rem',
		// lineHeight: '1.75em',
	},
	img: {
		display: 'block',
	},
	bottomAngle: {
		position: 'relative',
		marginTop: "-5.25rem",
	},
	topAngle: {
		position: 'relative',
		top: 0,
		zIndex: 10000,
	},
	leftAngle: {
		position: 'relative',
		top: '-2.5rem'
	},
	blockQuote: {
		background: '#5390cd', color: '#343b4d', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4rem 0 1rem 0',
		[theme.breakpoints.up('md')]: {
			margin: '6rem 0 0 0'
		}
	}
}));

export default function (props) {
	const classes = tpaStyles();

	// const SvgBG = (props) => (
	// 	<svg fill={props.fill} viewBox="0 0 100 5" preserveAspectRatio="none" width="100%" height="100%">
 //      <polygon points={getPoints(props)}  strokeWidth=".25" strokeLinecap="round"></polygon>
 //    </svg>
	// )

	// const getPoints = (props) => (
	// 	"0,0 100,0 " + props.focalPoint + ",5 0,0"
	// )


	const tpaList = () => ([
		{ link: '/', text: 'Claim intake' },
		{ link: '/', text: 'Claim assignment' },
		{ link: '/', text: 'Coverage review and analysis' },
		{ link: '/', text: 'Reserve establishment' },
		{ link: '/', text: '100% quality assurance reviews of all field assignments' },
		{ link: '/', text: 'Secondary reviews on large and/or complex claims' },
		{ link: '/', text: 'Issuance of claim payments' },
		{ link: '/', text: 'Settlement calls' },
		{ link: '/', text: 'Settlement letter preparation' },
		{ link: '/', text: 'Partial and full claim denial documentation and communication' },
		{ link: '/', text: 'Supplement and recoverable depreciation handling' },
		{ link: '/', text: 'Handling of customer complaints including insured, broker and department of insurance inquiries' },
		{ link: '/', text: 'Utilization of expert panels including engineers, building consultants, forensic accountants and water mitigation companies as needed' },
		{ link: '/', text: 'Subrogation, salvage and SIU pursuit, where applicable' },
	])

	

	return (
		<div className={classes.root}>
		<Grid container spacing={0}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0 }}>	
			<Grid item xs={12} md={7}>
			<Typography variant='h5' style={{ fontWeight: 600, color: '#343b4d' }}>
				Our TPA Services Include:					
			</Typography>
				<List component="ul" aria-label="list">
	    	{	tpaList().map((item, index) => (
	    		<React.Fragment key={index}>
	    			<ListItem className={classes.listItem}>
		    			<ListItemIcon>
		    				<CheckCircleIcon color="primary"/>
	  					</ListItemIcon> 
		          <ListItemText>
		          	<Typography variant='h6' style={{ color: '#323b4d' }} className={classes.listText}>
									{ item.text }  
								</Typography>
		          </ListItemText>
		        </ListItem>
		      <Divider light={true} className={classes.divider} />
		      </React.Fragment>
	    		))}
     		</List>
			</Grid>
  	</Grid>
  	<Grid container spacing={0} style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
			<Grid item xs={12}>
				<Typography variant="h5">We know that no two TPA programs are the same and will work with you to customize a specific program. From claim handling to claim system integration, we have you covered.</Typography>
			</Grid>
		</Grid>
	</div>
	)
}